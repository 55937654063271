<template>
  <div class="content">
    <el-form :model="app" ref="appForm" :rules="appRules" label-width="90px" size="medium" class="form">
      <el-form-item label="版本名称:" prop="appVersion">
        <el-input v-model="app.appVersion"></el-input>
      </el-form-item>
      <el-form-item label="卫兵程序:" prop="file">
        <el-upload action="/business/upload" class="upload-files"
                   :file-list="app.file"
                   :on-remove="removeFile"
                   :on-change="fileChange"
                   :http-request="uploadFile">
          <el-button size="small" type="primary">上传文件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="btn-group">
      <el-button size="small" type="primary" @click="update"  class="mgl10">强制更新</el-button>
    </div>
    <div class="footer" v-if="appInfo">
      <div>
        当前版本号：{{appInfo.appVersion}}
      </div>
      <div>
        更新时间：{{appInfo.updateTime}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "appUpdate",
  data(){
    let validateFile = (rule,value,callback)=>{
      if(!value||value.length==0){
        return callback(new Error('请上传卫兵程序'))
      }
      callback()
    }
    return {
      app:{},
      appInfo:null,
      appRules:{
        appVersion:[
            this.$formValidator.empty('版本名称')
        ],
        file:[
          {required:true,validator:validateFile,trigger:['blur','change']}
        ]
      },
    }
  },
  created(){
    this.emptyApp();
    this.getAppInfo()
  },
  methods:{
    getAppInfo(){
      this.$api.sysapi.appVersion().then(({data})=>{
        this.appInfo = data;
      })
    },
    update(){
      this.$refs.appForm.validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('file',this.app.file[0])
          formData.append('appVersion',this.app.appVersion)
          formData.append('forceUpdate',1)
          this.$api.sysapi.appUpdate(formData).then(({code,msg})=>{
            this.$message[code===200?'success':'error'](msg)
            this.emptyApp();
            this.getAppInfo();
          })
        }
      })
    },
    emptyApp(){
      this.app = {
        appVersion:null,
        file:[]
      }
    },
    removeFile(){
      this.app.file = []
    },
    uploadFile({file}){
      let formData = new FormData();
      formData.append('file',file);
      this.$api.sysapi.uploadFile(formData,'app').then((code,data,msg)=>{

      })
    },

    fileChange(file,filelist){
      this.app.file = [file.raw]
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  border-radius: 6px;
  padding: 20px;

  .form {
    height: 200px;
   .el-input{
     width: 200px;
   }
    .upload-files{
      width: 400px;
    }
  }
  .footer{
    position: fixed;
    bottom: 120px;
    right: 120px;
    font-size: 16px;
    color: #8b8686;
    div{
      height: 34px;
      line-height: 34px;
    }
  }
}

</style>